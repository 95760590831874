import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';
import { ShareholdersType } from 'types/about-us-hub-page.d';

import Layout from 'components/templates/layout';
import GroupHrk, { GroupHrkType } from 'components/organisms/group-hrk';
import HalfBannerDescription, {
  HalfBannerDescriptionType,
} from 'components/organisms/half-banner-description';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import KestriaBanner, { KestriaBannerType } from 'components/organisms/kestria-banner';
import Shareholders from 'components/organisms/shareholders';
import Breadcrumb from 'components/atoms/breadcrumb';
import { ButtonType } from 'components/atoms/button';

type DataProps = {
  aboutUsHubPage: {
    template: {
      abousUsData: {
        bannerPrimary: BannerPrimaryType;
        hrkGroup: GroupHrkType;
        banners: {
          bannerSecondary: HalfBannerDescriptionType;
        }[];
        kestriaBanner: KestriaBannerType;
        employeeShareOwnership: {
          ShareOwnershipLinkButton: ButtonType;
        };
      };
    };
  };
  stockOwnershipPage: {
    template: {
      stockOwnershipData: {
        bannerSimple: {
          heading: string;
        };
        stockOwnership: Pick<ShareholdersType, 'krs' | 'companyName' | 'address'>;
      };
    };
  };
};

export const AboutUsHubPage = ({
  pageContext,
  data: {
    aboutUsHubPage: {
      template: {
        abousUsData: {
          bannerPrimary: { heading, subHeading, image, text },
          hrkGroup,
          banners,
          kestriaBanner,
          employeeShareOwnership: { ShareOwnershipLinkButton },
        },
      },
    },
    stockOwnershipPage: {
      template: {
        stockOwnershipData: { bannerSimple, stockOwnership },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      showDots
      titleTop={heading}
      subtitleTop={subHeading}
      image={image}
      description={text}
      positionTitle="center"
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <GroupHrk {...hrkGroup} />
    {banners
      ? banners.map((banner) => (
          <HalfBannerDescription
            key={banner.bannerSecondary.bannerHeading}
            {...banner.bannerSecondary}
            isSmall={false}
          />
        ))
      : null}
    <KestriaBanner {...kestriaBanner} />
    <Shareholders
      {...stockOwnership}
      {...{ bannerSimple }}
      companyInfoUri={ShareOwnershipLinkButton}
    />
  </Layout>
);

export const query = graphql`
  query AboutUsHubPage($id: String, $langCode: String) {
    aboutUsHubPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          abousUsData {
            bannerPrimary {
              heading
              subHeading
              text
              image {
                ...WpImageFragment
              }
            }
            hrkGroup {
              citeText: cite
              organizationLinkButton {
                label
                icon {
                  name
                }
                link {
                  ariaLabel
                  external {
                    title
                    url
                  }
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
              groupBanner: image {
                ...WpImageFragment
              }
              groupHeading: heading
              groupText: text
              groupLogos {
                image {
                  ...WpImageFragment
                }
              }
              groupLinkButton {
                label
                icon {
                  name
                }
                link {
                  ariaLabel
                  external {
                    title
                    url
                  }
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
            banners {
              bannerSecondary {
                bannerRight: alignRight
                bannerHeading: heading
                bannerSubHeading: subHeading
                bannerText: text
                bannerImage: image {
                  ...WpImageFragment
                }
                bannerLinkButton: linkButton {
                  icon {
                    name
                  }
                  label
                  link {
                    ariaLabel
                    external {
                      target
                      title
                      url
                    }
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                  }
                }
              }
            }
            employeeShareOwnership {
              ShareOwnershipLinkButton: linkButton {
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
            kestriaBanner {
              kestriaImage: image {
                ...WpImageFragment
              }
              kestriaText: text
              kestriaLinkButton: linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  external {
                    title
                    url
                  }
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    stockOwnershipPage: wpPage(
      template: { templateName: { eq: "CompanyInfo" } }
      language: { slug: { eq: $langCode } }
    ) {
      template {
        ... on WpTemplate_CompanyInfo {
          stockOwnershipData {
            bannerSimple {
              heading
            }
            stockOwnership {
              companyName {
                nameOfCompany: text
              }
              krs {
                text
              }
              address {
                addressText: text
              }
            }
          }
        }
      }
    }
  }
`;
export default AboutUsHubPage;
