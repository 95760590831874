import React from 'react';

import CiteButtonCenter from 'components/molecules/cite-button-center';
import { Button, ButtonType } from 'components/atoms/button';
import { WpImageType } from 'components/atoms/wp-image';

import {
  StyledBackground,
  StyledContainer,
  StyledContainerBanner,
  StyledDescriptionGroup,
  StyledLogoItem,
  StyledTitleGroup,
  StyledWpImage,
  StyledWrapper,
  StyledWrapperAction,
  StyledWrapperBanner,
  StyledWrapperLogoItems,
} from './group-hrk.styles';

export type GroupHrkType = {
  organizationLinkButton: ButtonType;
  groupBanner: WpImageType;
  groupLogos: {
    image: WpImageType;
  }[];
  groupLinkButton: ButtonType;
} & Record<'citeText' | 'groupHeading' | 'groupText', string>;

const GroupHrk = ({
  groupHeading,
  groupLogos,
  groupBanner,
  groupLinkButton,
  groupText,
  citeText,
  organizationLinkButton,
}: GroupHrkType) => (
  <StyledBackground>
    <CiteButtonCenter citeText={citeText} button={organizationLinkButton} />
    <StyledWrapperBanner>
      <StyledContainerBanner>
        <StyledWpImage {...groupBanner} />
      </StyledContainerBanner>
    </StyledWrapperBanner>
    <StyledWrapper>
      <StyledContainer>
        <StyledTitleGroup color="white" html={groupHeading} variant="title1" component="h2" />
        <StyledDescriptionGroup color="white" html={groupText} variant="body1" />
        {groupLogos?.length ? (
          <StyledWrapperLogoItems>
            {groupLogos.map((logo) => (
              <StyledLogoItem objectFit="contain" {...logo.image} />
            ))}
          </StyledWrapperLogoItems>
        ) : null}
        <StyledWrapperAction>
          <Button variant="quaternary" {...groupLinkButton} />
        </StyledWrapperAction>
      </StyledContainer>
    </StyledWrapper>
  </StyledBackground>
);

export default GroupHrk;
