import React from 'react';

import Button, { ButtonType } from 'components/atoms/button';

import { StyleCiteText, StyledWrapper } from './cite-button-center.styles';

export type TitleButtonCenterType = {
  citeText: string;
  button?: ButtonType;
};

const CiteButtonCenter = ({ citeText, button }: TitleButtonCenterType) => (
  <StyledWrapper>
    <StyleCiteText color="gray100" variant="title1" component="p" html={citeText} />
    <Button variant="primary" {...button} />
  </StyledWrapper>
);

export default CiteButtonCenter;
