import styled from 'styled-components';
import { breakpoint } from 'theme';

import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

export const StyledBackground = styled.div`
  background: ${({ theme }) => theme.colors.grayscaleGray0};
`;

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary50};
`;

export const StyledWrapperBanner = styled.div`
  position: relative;

  &:before {
    width: 100%;
    height: 50%;
    max-height: 160px;
    left: 0;
    bottom: 0;
    position: absolute;
    background: ${({ theme }) => theme.colors.primary50};
    content: '';
  }

  ${breakpoint.md} {
    &:before {
      max-height: 320px;
    }
  }
`;

export const StyledWrapperAction = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.gap.smd};

  ${breakpoint.md} {
    padding-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledContainer = styled(Container)`
  max-width: 920px;
  text-align: center;
  padding-bottom: 36px;

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledLogoItem = styled(WpImage)`
  max-width: 180px;
`;

export const StyledWpImage = styled(WpImage)`
  z-index: 2;
  height: 100%;
  min-height: 216px;
`;

export const StyledContainerBanner = styled.div`
  padding-inline: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    max-width: ${({ theme }) => theme.wrapperXl};
    margin: auto;
  }
`;

export const StyledTitleGroup = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.md} 24px;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl} ${({ theme }) => theme.gap.md};
  }
`;

export const StyledDescriptionGroup = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.gap.smd};

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledWrapperLogoItems = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gap.smd};
  justify-content: center;
  align-items: center;

  ${breakpoint.md} {
    gap: 70px;
  }
`;
