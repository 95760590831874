import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  max-width: 928px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: ${({ theme }) => theme.gap.md};
  background: ${({ theme }) => theme.colors.grayscaleGray0};
  position: relative;

  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    bottom: -100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.grayscaleGray0};
    z-index: -1;
  }

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
    background: unset;

    &::after {
      content: unset;
    }
  }
`;

export const StyleCiteText = styled(Typography)`
  padding-bottom: 24px;

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.md};
  }
`;
