import React from 'react';
import { ShareholdersType } from 'types/about-us-hub-page.d';

import { Button, ButtonType } from 'components/atoms/button';
import Typography from 'components/atoms/typography';

import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledWrapperDescription,
} from './shareholders.styles';

export type ShareholdersTypeProps = {
  bannerSimple: {
    heading: string;
  };
  companyInfoUri: ButtonType;
} & ShareholdersType;

const Shareholders = ({
  krs: { text },
  address: { addressText },
  bannerSimple: { heading },
  companyName: { nameOfCompany },
  companyInfoUri,
}: ShareholdersTypeProps) => (
  <StyledBackground>
    <StyledContainer>
      <StyledWrapper>
        <div>
          <Typography variant="title3" color="gray40" component="h3" html={heading} />
        </div>
        <StyledWrapperDescription>
          <Typography variant="title2" component="h2" color="gray100" html={nameOfCompany} />
          <Typography variant="body1" color="gray100" html={addressText} />
          <Typography variant="body1" color="gray100" html={text} />
          <Button {...companyInfoUri} variant="underline" />
        </StyledWrapperDescription>
      </StyledWrapper>
    </StyledContainer>
  </StyledBackground>
);

export default Shareholders;
