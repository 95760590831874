import styled from 'styled-components';
import { breakpoint } from 'theme';

import Container from 'components/atoms/container';

export const StyledBackground = styled.div`
  ${breakpoint.md} {
    margin-top: -260px;
    padding-top: 180px;
    background-color: ${({ theme }) => theme.colors.grayscaleGray0};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    flex-direction: row;
    display: grid;
    grid-template-columns: 33% 67%;
  }
`;

export const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.wrapper};
  padding-block: ${({ theme }) => theme.gap.md};
  background-color: ${({ theme }) => theme.colors.grayscaleGray0};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
    background-color: unset;
  }
`;

export const StyledWrapperDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    max-width: 733px;
  }
`;
